import React from 'react'
import Link from 'gatsby-link'
import Layout from '../../../components/layout'
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import "animate.css/animate.css";
import ScrollAnimation from 'react-animate-on-scroll';
import InstLogo from './instructure-logo.svg';
import styled from "@emotion/styled";

import OldHome from "./OldHomePage.png"
import NewHome from "./NewHomePage.png"


const RED = styled.span`
  color: #c00;
`


const H4 = styled.h4`
 text-transform: uppercase;
 letter-spacing: .05em;
 color: #c00;
`
const Stack = styled.div`
  margin-top:40px;
  background-color: #efefef;
`
const Image = styled.img`
  border:1px solid #eee;
  object-fit: cover;  
`

const Caption = styled.p`
  font-size: .75em;
  opacity: .5;
`
const CatTitle = styled.p`
font-size: .75em;
text-transform: uppercase;
color: #000;
opacity: .5;
letter-spacing: .1  em;
`

const H1 = styled.h1`
margin-top: 0;
`



export default () => (
  <Layout>
    <PageTransition>
      <BodyClassName className="">
        <div className="page">
          <article className="">
            <section className="contentSection">
              <div className="row">
                <div className="col-xs-9">
                  <header>    
                  <Link to="./casestudies"><CatTitle>Case Study</CatTitle></Link>                
                    <H1>Leading Design Systems at Instructure</H1>
                  </header>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-7 col-md-8">
                  <H4>The Problem</H4>
                  <p className="introText">
                    Previous to my employment, a UI development team at Instructure had invested several years and had created a very solid and complete design system (<a className="link" href='https://instructure.design'>Instructure-ui</a>). 
                  </p>
                  <p className="introText">
                    Product design and engineering leadership desired to accelerate adoption of the design system by engineering teams by improving the system and fostering better relationships between UI developement, design and engineering. 
                  </p>
                  <H4>My Approach</H4>
                  <p className="introText">
                   
                 </p>
                  <p className="introText">
                    Our team engaged in a series of one-on-one interviews with engineers and designers to understand better how they use the system and what we could do to improve. After interviews, we developed a plan for future releases that would improve developer experience while meeting product design's goals for improving the system.
                  </p>
                </div>

                <div className="col-xs-12 col-sm-5 col-md-4">
                  <ScrollAnimation animateIn="fadeIn" duration={.5}>
                    <p><img className="full" src={InstLogo} style={{ "maxWidth": "300px" }} alt="Ancestry" /></p>

                    <ul>
                      <li>Organized and conducted interviews with engineering, design, and external developers using Instructure UI.</li>
                      <li>Implemented metrics to track usage and adoption of design system.</li>
                      <li>Coordinated work between product design and engineering.</li>
                      <li>Collaborated to create design system assets in Figma and Framer X.</li>
                      <li>Implemented process for making updates and evolving design system.</li>                      
                    </ul>
                  </ScrollAnimation>
                </div>
              </div>
            </section>

            <Stack>
            <section className="contentSection">
              <h2>Design System Metrics</h2>
                              
                <p>To understand better how developers and designers were using the design system, metrics were identified to help us focus efforts in the right place.</p>


                <p><strong>Satisfaction</strong> — Transcripts and notes from user interviews provided us with a very detailed view of how the design system was percieved by design and developerment. Counting positive and negative sentiment from interviews gave as a high level view of how the product was perceived. Tracking this in the future would happen via regular surveys.</p>
                <p><strong>Design System Adoption Rate</strong> — I wrote a script to search Instructure's code base to identify instances where our components were used in software. This script would run monthly allowing us to see how many new instances of our system compenents were added to the code base, and how many old (deprecated) components were removed. While simple, this provided a monthly view of high level activity with the design system.</p>
                <p><strong>Documentation Analytics</strong> — Google Analytics was added to our design system docs allowing us to see pages that get the most use. This allowed us to tailor the message better and develop better documentation or component improvements where we could see trends in our documentation usage patterns.</p>                                             
              
            </section>
            </Stack>


            <div>
              <section className="contentSection">
                <h2>Internal to External</h2>
                <p>While Instructure UI was primarily an internal design system used in Canvas and other Instructure applications. One goal was to position Instructure UI as a valuable part of the Canvas platform for external customers building applications for Canvas, and not just an internal development tool. The first step was to align the Instructure UI documentation site to Instructure's corporate brand. </p>
                <div className="row">
                <div className="col-xs-12 col-md-6">   
                <a href="https://instructure.design"><Image src={NewHome} style={{"width":"100%"}}/></a>
                  <Caption>A new home page design (by Chris Hart) provided close alignment with the Instructure brand and a more focused message to help potential consumers understand the value and use of the design system.</Caption>
                  </div>   
                  <div className="col-xs-12 col-md-6">   
                  <Image src={OldHome} style={{"width":"100%"}}/>
                  <Caption>The previous design system documentation landing page was functional, but didn't align with company brand and didn't communicate important features that make the system valuable.</Caption>
                  </div>
                           
                      
                
                </div>
              </section>
            </div>
           
    </article>

  </div>

  </BodyClassName>
  </PageTransition>
  </Layout >
)
